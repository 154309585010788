import { usePixiMouseEventListener } from "@poscon/shared-frontend";
import { Container } from "pixi.js";
import { RefObject, useState } from "react";
import { useTimeout } from "usehooks-ts";

export const usePressedListener = (ref: RefObject<Container | null>, momentary = true, suppress = false) => {
  const [pressed, setPressed] = useState(false);

  usePixiMouseEventListener(() => {
    setPressed(true);
  }, ref, true, "pointerdown");

  useTimeout(() => {
    setPressed(false);
  }, pressed && momentary ? 120 : null);

  usePixiMouseEventListener(() => {
    if (!momentary) {
      setPressed(false);
    }
  }, ref, true, "pointerup");

  usePixiMouseEventListener(() => {
    if (!momentary) {
      setPressed(false);
    }
  }, ref, true, "pointerleave");

  return !suppress && pressed;
}
