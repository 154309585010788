import { bitmapTextBaseStyle, fontHeight, fontWidth, freqButtonGapSize, functionButtonWidth, vscsColorNameMap } from "~/constants";
import React from "react";

export const freqButtonWidth = freqButtonGapSize * 2 + functionButtonWidth * 3;
export const freqAreaHeight = fontHeight;

type FreqAreaProps = {
  freq: number;
  isEmergFreq?: boolean;
  highlight?: boolean;
};
export const FreqArea = ({ freq, isEmergFreq, highlight }: FreqAreaProps) => {
  const _color = isEmergFreq ? vscsColorNameMap.red : vscsColorNameMap.cyan;
  const color = highlight ? vscsColorNameMap.violet : _color;
  const _tint = isEmergFreq ? vscsColorNameMap.white : vscsColorNameMap.black;
  const tint = highlight ? vscsColorNameMap.white : _tint;
  const text = freq.toFixed(3);
  return (
    <container>
      <graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, freqButtonWidth, freqAreaHeight).fill(color);

          graphics.setStrokeStyle({});

          graphics
            .moveTo(7, freqAreaHeight)
            .lineTo(7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.floor(freqButtonWidth / 3) - 12, Math.floor(freqAreaHeight / 2))
            .stroke({ width: 1, color: tint });

          graphics
            .moveTo(freqButtonWidth - 7, freqAreaHeight)
            .lineTo(freqButtonWidth - 7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.ceil((freqButtonWidth * 2) / 3 + 12), Math.floor(freqAreaHeight / 2))
            .stroke({ width: 1, color: tint });
        }}
      />
      <bitmapText
        x={Math.floor((freqButtonWidth - text.length * fontWidth) / 2)}
        y={1}
        text={text}
        tint={tint}
        style={{ ...bitmapTextBaseStyle, fill: tint }}
      />
    </container>
  );
};
