import React from "react";
import { freqAreaHeight } from "components/FreqArea";
import { bitmapTextBaseStyle, buttonHeight, fontWidth, freqButtonGapSize, functionButtonWidth, gapSize, vscsColorNameMap } from "~/constants";
import { totalButtonHeight } from "./FrequencyButton";
import { StatusArea, statusAreaWidth } from "./StatusArea";

export const auxMessageAreaWidth = functionButtonWidth * 2 + freqButtonGapSize;
export const auxMsgAreaX = statusAreaWidth + freqButtonGapSize;

type AuxMessageAreaProps = {
  x?: number;
  y?: number;
};
export const AuxMessageArea = ({ x = 0, y = 0 }: AuxMessageAreaProps) => {
  return (
    <container x={x} y={y}>
      <graphics
        draw={(graphics) => {
          graphics.clear();

          graphics.rect(0, freqAreaHeight, auxMessageAreaWidth, buttonHeight).fill(vscsColorNameMap.cyan);

          graphics.rect(0, 0, auxMessageAreaWidth, freqAreaHeight).fill(vscsColorNameMap.white);

          graphics.setStrokeStyle({});
          graphics
            .moveTo(7, freqAreaHeight)
            .lineTo(7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.floor(auxMessageAreaWidth / 4 - 5), Math.floor(freqAreaHeight / 2))
            .stroke({ width: 1, color: vscsColorNameMap.black });

          graphics
            .moveTo(auxMessageAreaWidth - 7, freqAreaHeight)
            .lineTo(auxMessageAreaWidth - 7, Math.floor(freqAreaHeight / 2))
            .lineTo(Math.ceil((auxMessageAreaWidth * 3) / 4 + 5), Math.floor(freqAreaHeight / 2))
            .stroke({ width: 1, color: vscsColorNameMap.black });
        }}
      />
      <bitmapText
        x={Math.floor((auxMessageAreaWidth - 7 * fontWidth) / 2)}
        y={1}
        text="R/T OFF"
        style={{ ...bitmapTextBaseStyle, fill: vscsColorNameMap.black }}
      />
      <bitmapText
        y={freqAreaHeight}
        text={"CALLS FWD TO:\nH86"}
        style={{ ...bitmapTextBaseStyle, fill: vscsColorNameMap.black }}
      />
    </container>
  );
};

export const AuxComponent = ({ children }: { children?: React.ReactNode }) => {
  return (<container x={0} y={totalButtonHeight * 4}>
    <StatusArea />
    <AuxMessageArea x={auxMsgAreaX} />
    <container x={auxMsgAreaX + auxMessageAreaWidth + gapSize}>
      {children}
    </container>
  </container>);
}