/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useMemo } from "react";
import { AGPositionId } from "~/redux/slices/vscsSlice";
import { isString } from "@poscon/shared-types";
import { env } from "~/env";
import { posconApi } from "@poscon/shared-frontend";

const baseUrl = env.VITE_STRATUS_FACILITY_DATA_URL;

type Frequency = number;

export type AirGroundPosition = {
  buttonId: AGPositionId;
  vhfFreq?: Frequency;
  uhfFreq?: Frequency;
  transmitterName: string;
  backupTransmitterName?: string;
  isEmergFreq: boolean;
  isDivAlgo?: boolean;
  remoteMute?: boolean;
  xcpl?: Frequency;
};

export type VscsApiResponse = {
  sectorId: string;
  role: string;
  airGroundPositions: AirGroundPosition[];
};

export type RcagId = string;

export type Rcag = {
  rcagId: RcagId;
  latitude: number;
  longitude: number;
  elevation: number;
};

// TODO: store this somewhere and update when getting 401 or 403
const accessToken = await posconApi.getAtcAccessToken();

// Define a service using a base URL and expected endpoints
export const vscsApi = createApi({
  reducerPath: "vscsConfig",
  baseQuery: fetchBaseQuery({
    baseUrl,
    mode: "cors",
    prepareHeaders: (headers) => {
      headers.set("Credentials", "include");
      headers.set("Authorization", `${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    vscsConfig: builder.query<VscsApiResponse, [string, string]>({
      query: ([sectorId, role]) => `${sectorId.split(".")[0]!}/eramVscsConfig/${sectorId}/${role}`,
    }),
    rcags: builder.query<Rcag[], string>({
      query: () => `rcags`,
    }),
  }),
});

export const { useVscsConfigQuery, useRcagsQuery } = vscsApi;

// for testing
const defaultSectorId = "KZNY.ZNY.86";

const urlParams = new URLSearchParams(window.location.search);

export const useAirGroundPositions = () => {
  const urlSectorId = (urlParams.get("sectorId") ?? defaultSectorId).split(".");
  const sectorId = [...urlSectorId.slice(0, 2), urlSectorId.at(-1)].join(".");
  const role = urlParams.get("role") ?? "Radar";
  const query = useMemo<[string, string]>(() => [sectorId, role], [sectorId, role]);
  const { data } = useVscsConfigQuery(query);
  return useMemo(() => data?.airGroundPositions ?? [], [data]);
};

export const useRcags = () => {
  const urlSectorId = urlParams.get("sectorId") ?? defaultSectorId;
  const { data } = useRcagsQuery(urlSectorId.split(".")[0]!);
  return useMemo(() => data ?? [], [data]);
};
