import * as Sentry from "@sentry/react";
import { env } from "./env";
import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { PosconStage } from "@poscon/shared-frontend";
import { Provider } from "react-redux";
import { store } from "~redux/store";
import { Vscs } from "components/Vscs";
import { totalButtonHeight } from "components/FrequencyButton";
import { gapSize, numPanels, vscsColorNameMap } from "~/constants";
import { freqButtonWidth } from "components/FreqArea";
import { Assets } from "pixi.js";
import { vikWidth } from "./components/vik/Vik";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import 'pixi.js/text-bitmap';

Sentry.init({
  dsn: env.VITE_SENTRY_URL_VSCS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  sampleRate: 1,
  // Tracing
  tracesSampleRate: 0.001,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["hq.poscon.local", "https://services.poscon.com/eram"],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.1,
});

await Promise.all([
  Assets.load([new URL("/fonts/vscs.xml", import.meta.url).href]),
  Assets.load([new URL("/fonts/vscsSmall.xml", import.meta.url).href]),
  Assets.load([new URL("/fonts/vscsSpecialNumbers.xml", import.meta.url).href]),
  Assets.load([new URL("/fonts/vscsIcons.xml", import.meta.url).href]),
  Assets.load([new URL("/fonts/vikNumpad.xml", import.meta.url).href]),
  Assets.load([new URL("/fonts/vikNumDisplay.xml", import.meta.url).href]),
  Assets.load([new URL("/fonts/vikBright.xml", import.meta.url).href]),
  Assets.load([new URL("/fonts/vikIcons.xml", import.meta.url).href]),

]);

const width = freqButtonWidth * 3 + gapSize * 2;
const height = totalButtonHeight * 6;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <div id="vscsRoot" className="root" onContextMenu={(e) => e.preventDefault()}>
      <PosconStage
        resizeToWindow={false}
        backgroundColor={vscsColorNameMap.darkGrey}
        width={vikWidth + 20 + (numPanels === 2 ? width * 2 + 20 : width)}
        height={height}
      >
        <Provider store={store}>
          <Vscs />
        </Provider>
      </PosconStage>
    </div>
  </React.StrictMode>,
);

if (window.__TAURI__) {
  const webviewWindow = WebviewWindow.getCurrent();
  await webviewWindow.listen("poscon:close", async () => {
    void webviewWindow.close();
  });
}
