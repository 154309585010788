import React from "react";
import { buttonHeight, fontHeight, fontWidth, freqButtonGapSize, functionButtonWidth, gapSize, statusFontName, vscsColorNameMap } from "~/constants";
import { freqAreaHeight } from "components/FreqArea";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { selectStatusMsg, setStatusMsg, VscsStatusMessageType } from "~/redux/slices/vscsSlice";
import { ERROR_ICON } from "~/symbols";
import { useVscsPanel } from "~/contexts/vscsPanelContext";

export const statusAreaWidth = functionButtonWidth * 4 + gapSize + freqButtonGapSize * 2;

const iconMap: Record<VscsStatusMessageType, string | null> = {
  system: null,
  error: ERROR_ICON,
  warning: ERROR_ICON,
};

type StatusAreaProps = {
  x?: number;
  y?: number;
};
export const StatusArea = ({ x = 0, y = 0 }: StatusAreaProps) => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const status = useRootSelector((state) => selectStatusMsg(state, panel));

  const icon = status ? iconMap[status.type] : null;

  return (
    <container x={x} y={y + freqAreaHeight}>
      <graphics
        eventMode={status ? "static" : "none"}
        onPointerDown={() => {
          dispatch(setStatusMsg({ msg: null, panel }));
        }}
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, statusAreaWidth, buttonHeight).fill(status ? vscsColorNameMap.pink : vscsColorNameMap.grey);
        }}
      />
      {status?.msg && (
        <bitmapText
          x={fontWidth * 3 + 4}
          y={fontHeight}
          eventMode="none"
          text={status.msg}
          style={{ fontFamily: statusFontName, fontSize: 14, fill: 0 }}
        />
      )}
      {icon && (
        <bitmapText
          x={0}
          y={buttonHeight / 2}
          anchor={{ x: 0, y: 0.5 }}
          eventMode="none"
          text={icon}
          style={{ fontFamily: statusFontName, fontSize: 14, fill: 0 }}
        />
      )}
    </container>
  );
};
