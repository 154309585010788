import React, { ComponentType, useEffect } from "react";
import { useRootSelector } from "~redux/hooks";
import {
  agButtonIds,
  selectAirGroundPositionState,
  selectFreqRxIdMap,
  selectPage,
  VscsPage,
} from "~/redux/slices/vscsSlice";
import { AirGroundPage } from "./AirGroundPage";
import { Footer } from "./Footer";
import { useVscsPanel, VscsPanelContextProvider } from "~/contexts/vscsPanelContext";
import { useAirGroundPositions, useRcags } from "~/api/api";
import { removeXmtr, setupXmtr } from "~/commands";
import { AuxComponent } from "./AuxMessageArea";
import { GroundGroundPage } from "./GroundGroundPage";
import { numPanels } from "~/constants";
import { Vik } from "./vik/Vik";

const pageComponentMap: Record<VscsPage, ComponentType | null> = {
  "A/G_1": () => <AirGroundPage page={0} />,
  "A/G_2": () => <AirGroundPage page={1} />,
  "A/G_STATUS": null,
  "G/G_1": () => <GroundGroundPage page={0} />,
  "G/G_2": () => <GroundGroundPage page={1} />,
  UTIL: null,
};

export const VscsPanel = ({ x = 0, y = 0 }) => {
  const panel = useVscsPanel();
  const page = useRootSelector((state) => selectPage(state, panel));

  const PageComponent = pageComponentMap[page];

  return (
    <container x={x} y={y}>
      {PageComponent && <PageComponent />}
      {!PageComponent && <AuxComponent />}
      <Footer />
    </container>
  );
};

export const Vscs = () => {
  const airGroundPositions = useAirGroundPositions();
  const positionStates = useRootSelector(selectAirGroundPositionState);
  const freqRxIdMap = useRootSelector(selectFreqRxIdMap);
  const rcags = useRcags();

  // TODO: only do stuff here when rcags or AG positions change and handle rest in listener middleware
  useEffect(() => {
    if (window.__TAURI__) {
      for (const buttonId of agButtonIds) {
        const agPos = airGroundPositions.find((p) => p.buttonId === buttonId);
        if (!agPos) {
          removeXmtr(buttonId);
          continue;
        }
        const state = positionStates[agPos.buttonId];
        const rcag = rcags.find((r) => agPos.transmitterName === r.rcagId);
        const backupRcag = rcags.find((r) => agPos.backupTransmitterName === r.rcagId);
        const freq = agPos.vhfFreq ?? agPos.uhfFreq ?? 0;
        const rx = state.rx && !state.remMute ? 1 : 0;
        const tx = freq !== 0 && freqRxIdMap[freq] === buttonId ? 1 : 0;
        if (state.buecSelected && backupRcag) {
          setupXmtr({
            btnId: buttonId,
            id: backupRcag.rcagId,
            lon: backupRcag.longitude,
            lat: backupRcag.latitude,
            elevation: backupRcag.elevation,
            vhfFreq: Math.trunc((agPos.vhfFreq ?? 0) * 1000),
            uhfFreq: Math.trunc((agPos.uhfFreq ?? 0) * 1000),
            rx,
            tx,
          });
        } else if (rcag) {
          setupXmtr({
            btnId: buttonId,
            id: rcag.rcagId,
            lon: rcag.longitude,
            lat: rcag.latitude,
            elevation: rcag.elevation,
            vhfFreq: Math.trunc((agPos.vhfFreq ?? 0) * 1000),
            uhfFreq: Math.trunc((agPos.uhfFreq ?? 0) * 1000),
            rx,
            tx,
          });
        }
      }
    }
  }, [airGroundPositions, positionStates, freqRxIdMap, rcags]);

  return (<>
    <VscsPanelContextProvider panel={1}>
      <VscsPanel />
    </VscsPanelContextProvider>
    <Vik x={820} />
    {numPanels === 2 && (
      <VscsPanelContextProvider panel={2}>
        <VscsPanel x={1160} />
      </VscsPanelContextProvider>
    )}
  </>);
}
