import React from "react";
import { useAirGroundPositions } from "~/api/api";
import { useRootSelector } from "~/redux/hooks";
import { AGPositionId, selectAirGroundPositionState, selectFreqRxIdMap } from "~/redux/slices/vscsSlice";
import {
  ButtonPositionIndex,
  SelectedFreqButton,
  UnassignedFreqButton,
  UnselectedFreqButton,
} from "./FrequencyButton";
import { AuxComponent } from "./AuxMessageArea";
import { EmergencyPttArea } from "./EmergencyPttButton";
import { mod } from "@poscon/shared-types";

type AirGroundPageProps = {
  page: 0 | 1;
};

export const AirGroundPage = ({ page }: AirGroundPageProps) => {
  const positionStates = useRootSelector(selectAirGroundPositionState);
  const freqRxIdMap = useRootSelector(selectFreqRxIdMap);
  const airGroundPositions = useAirGroundPositions();

  const it = Array.from({ length: 12 }, (_, i) => i + page * 12) as AGPositionId[];

  return (
    <container>
      {it.map((id, index) => {
        const state = positionStates[id];
        const posIndex = index as ButtonPositionIndex;
        const airGroundPosition = airGroundPositions.find(({ buttonId }) => buttonId === id);
        const freq = airGroundPosition?.vhfFreq ?? airGroundPosition?.uhfFreq ?? 0;
        if (!airGroundPosition || freq === 0) {
          return <UnassignedFreqButton key={id} posIndex={posIndex} />;
        }
        const xcplFreqPos = airGroundPosition.xcpl
          ? airGroundPositions.find(
              (p) => (p.vhfFreq ?? p.uhfFreq) === airGroundPosition.xcpl && mod(p.buttonId - id, 3) === 0,
            )
          : null;
        const xmtr = freqRxIdMap[freq] === id;
        const Component = xmtr || state.rx ? SelectedFreqButton : UnselectedFreqButton;
        return (
          <Component
            key={id}
            id={id}
            posIndex={posIndex}
            xmtr={xmtr}
            state={state}
            xcplPosButtonId={xcplFreqPos?.buttonId}
            position={airGroundPosition}
          />
        );
      })}
      <AuxComponent>
        <EmergencyPttArea />
      </AuxComponent>
    </container>
  );
};
