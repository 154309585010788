import { AGPositionId } from "~/redux/slices/vscsSlice";
import { AuxComponent } from "./AuxMessageArea";
import { AlternateFunctionButton } from "./FunctionButton";
import { buttonHeight, functionButtonXMapList, functionButtonWidth, gapSize, vscsColorNameMap, fontHeight, bitmapTextBaseStyle, fontWidth } from "~/constants";
import { freqAreaHeight } from "./FreqArea";

const ManRingButton = ({ x = 0, y = 0 }) => {
  return (
    <container
      x={x}
      y={y + freqAreaHeight}
      eventMode="static"
    >
      <graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, functionButtonWidth, buttonHeight).fill(vscsColorNameMap.grey).stroke();
          graphics.rect(6, 6, functionButtonWidth - 12, buttonHeight - 12).stroke({ width: 3, color: vscsColorNameMap.black });
        }}
      />
      <bitmapText
        x={Math.floor((functionButtonWidth - fontWidth * 4) / 2)}
        y={fontHeight + 4}
        text={"MAN\nRING"}
        style={{ ...bitmapTextBaseStyle, fill: vscsColorNameMap.black }}
      />
    </container>
  );
}

type GroundGroundPageProps = {
  page: 0 | 1;
};

export const GroundGroundPage = ({ page }: GroundGroundPageProps) => {

  const it = Array.from(
    { length: 25 },
    (_, i) => i + page * 25,
  ) as AGPositionId[];

  return <container>
    {it.map((id, index) => {
      const x = functionButtonXMapList[index % 9];
      const y = (buttonHeight + gapSize) * Math.floor(index / 9);
      return <AlternateFunctionButton key={id} x={x} y={y} bgTint={vscsColorNameMap.grey} text="" />
    })}
    <ManRingButton x={functionButtonXMapList[7]} y={(buttonHeight + gapSize) * 2} />
    <AlternateFunctionButton key="PROG_CONF" x={functionButtonXMapList[8]} y={(buttonHeight + gapSize) * 2} text={"PROG\nCONF"} />
    <AlternateFunctionButton key="CALL_ANS" x={functionButtonXMapList[6]} y={(buttonHeight + gapSize) * 3} text={"CALL\nANS"} />
    <AlternateFunctionButton key="HOLD" x={functionButtonXMapList[6]} y={gapSize + (buttonHeight + gapSize) * 4} text={"HOLD"} />
    <AuxComponent />
  </container>
}
