import React, { useEffect, useRef, useState } from "react";
import {
  bitmapTextBaseStyle,
  buttonHeight,
  fontHeight,
  fontWidth,
  functionButtonWidth,
  iconFontName,
  pageNumbersFontName,
  vscsColorNameMap,
} from "~/constants";
import type { ColorSource, Container as PixiContainer } from "pixi.js";
import { freqAreaHeight } from "components/FreqArea";
import { DIV_ALCO_ICON, STANDBY_S } from "~/symbols";
import { speakerModeIconMap } from "~/symbols";
import {
  AirGroundModifier,
  selectAirGroundModifier,
  selectPage,
  setAirGroundModifier,
  VscsPage,
  type SpeakerMode,
} from "~redux/slices/vscsSlice";
import { usePressedListener } from "~/hooks/usePressedListener";
import { useRootDispatch, useRootSelector } from "~/redux/hooks";
import { useInterval } from "usehooks-ts";
import { useVscsPanel } from "~/contexts/vscsPanelContext";

type FunctionButtonProps = {
  x?: number;
  y?: number;
  textAnchor?: { x: number; y: number };
  textX?: number;
  textY?: number;
  width?: number;
  bgTint: ColorSource;
  tint: ColorSource;
  text: string;
  bottomText?: string;
  speakerMode?: SpeakerMode;
  divAlgoSelected?: boolean;
  stbySelected?: boolean;
  onpointerdown?: () => void;
};
export const FunctionButton = ({
  x = 0,
  y = 0,
  width = functionButtonWidth,
  textX = functionButtonWidth / 2,
  textY = 0,
  textAnchor = { x: 0.5, y: 0 },
  bgTint,
  tint: _tint,
  text,
  bottomText,
  onpointerdown,
  speakerMode,
  stbySelected,
  divAlgoSelected,
}: FunctionButtonProps) => {
  const ref = useRef<PixiContainer>(null);
  const pressed = usePressedListener(ref);

  const bgColor = pressed ? vscsColorNameMap.blue : bgTint;
  const tint = pressed ? vscsColorNameMap.white : _tint;
  return (
    <container x={x} y={y} eventMode="static" ref={ref}>
      <graphics
        eventMode="static"
        onPointerDown={onpointerdown}
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, width, buttonHeight).fill(bgColor);
        }}
      />
      {text.split("\n").map((line, i) => {
        const x = textX;
        const y = textY + fontHeight * i;
        return (
          <bitmapText
            eventMode="none"
            key={i}
            x={x}
            y={y}
            roundPixels
            anchor={textAnchor}
            text={line}
            style={{ ...bitmapTextBaseStyle, fill: tint }}
          />
        );
      })}
      {speakerMode && (
        <bitmapText
          x={width / 2}
          y={fontHeight * 2}
          text={speakerModeIconMap[speakerMode]}
          roundPixels
          anchor={{ x: 0.5, y: 0 }}
          eventMode="none"
          style={{ fontFamily: iconFontName, fontSize: 36, fill: tint }}
        />
      )}
      {bottomText && (
        <bitmapText
          x={Math.floor(width / 2)}
          y={buttonHeight}
          text={bottomText}
          roundPixels
          anchor={{ x: 0.5, y: 1 }}
          eventMode="none"
          style={{ ...bitmapTextBaseStyle, fill: tint }}
        />
      )}
      {stbySelected && (
        <bitmapText
          x={10}
          y={buttonHeight}
          text={STANDBY_S}
          roundPixels
          anchor={{ x: 0, y: 1 }}
          eventMode="none"
          style={{ fontFamily: iconFontName, fontSize: 36, fill: tint }}
        />
      )}
      {divAlgoSelected && (
        <bitmapText
          x={width}
          y={buttonHeight}
          text={DIV_ALCO_ICON}
          anchor={{ x: 1, y: 1 }}
          eventMode="none"
          style={{ fontFamily: iconFontName, fontSize: 36, fill: tint }}
        />
      )}
    </container>
  );
};

type AlternateFunctionButtonProps = {
  x?: number;
  y?: number;
  textAnchor?: { x: number; y: number };
  textX?: number;
  textY?: number;
  width?: number;
  bgTint?: ColorSource;
  tint?: ColorSource;
  text: string;
  headerText?: string;
  onpointerdown?: () => void;
  speakerMode?: SpeakerMode;
};

export const AlternateFunctionButton = ({
  x = 0,
  y = 0,
  textAnchor = { x: 0, y: 0 },
  textX = fontWidth,
  textY = fontHeight,
  width = functionButtonWidth,
  bgTint = vscsColorNameMap.cyan,
  tint = vscsColorNameMap.black,
  text,
  speakerMode,
  headerText,
  onpointerdown,
}: AlternateFunctionButtonProps) => {
  return (
    <container x={x} y={y} eventMode={onpointerdown ? "passive" : "none"}>
      {headerText && (
        <>
          <graphics
            draw={(graphics) => {
              graphics.clear();
              graphics.rect(0, 0, width, freqAreaHeight).fill(vscsColorNameMap.white);
            }}
          />
          <bitmapText
            text={headerText}
            x={width / 2}
            roundPixels
            anchor={{ x: 0.5, y: 0 }}
            style={{ fill: vscsColorNameMap.black, ...bitmapTextBaseStyle }}
          />
        </>
      )}
      <FunctionButton
        x={0}
        y={freqAreaHeight}
        textAnchor={textAnchor}
        bgTint={bgTint}
        tint={tint}
        textX={textX}
        textY={textY}
        width={width}
        text={text}
        onpointerdown={onpointerdown}
        speakerMode={speakerMode}
      />
    </container>
  );
};

type ModifierButtonProps = {
  x?: number;
  y?: number;
  color?: ColorSource;
  toggleColor?: ColorSource;
  tint?: ColorSource;
  text: string;
  modifier: AirGroundModifier;
  children?: React.ReactNode;
};

export const ModifierButton = ({
  x = 0,
  y = 0,
  color = vscsColorNameMap.cyan,
  toggleColor = vscsColorNameMap.green,
  tint = 0,
  modifier,
  text,
  children,
}: ModifierButtonProps) => {
  const dispatch = useRootDispatch();
  const panel = useVscsPanel();
  const modifierState = useRootSelector((state) => selectAirGroundModifier(state, panel));
  const [blinkColor, setBlinkColor] = useState(false);

  const selected = modifierState === modifier;

  useInterval(
    () => {
      setBlinkColor((prev) => !prev);
    },
    selected ? 500 : null,
  );

  useInterval(
    () => {
      dispatch(setAirGroundModifier({ modifier: null, panel }));
    },
    selected ? 15e3 : null,
  );

  useEffect(() => {
    if (!selected) {
      setBlinkColor(false);
    }
  }, [selected]);

  const bgColor = selected && blinkColor ? toggleColor : color;

  return (
    <container x={x} y={y}>
      <FunctionButton
        onpointerdown={() => {
          dispatch(setAirGroundModifier({ modifier: !selected ? modifier : null, panel }));
        }}
        x={0}
        y={freqAreaHeight}
        textAnchor={{ x: 0, y: 0 }}
        textX={fontWidth}
        textY={fontHeight}
        bgTint={bgColor}
        tint={tint}
        text={text}
      />
      {children}
    </container>
  );
};

const pageTextMap: Record<VscsPage, string> = {
  "A/G_1": `A/G`,
  "A/G_2": `A/G`,
  "A/G_STATUS": "STATUS",
  "G/G_1": `G/G`,
  "G/G_2": `G/G`,
  UTIL: "UTIL",
};

const Page = () => {
  const panel = useVscsPanel();
  const page = useRootSelector((state) => selectPage(state, panel));
  const num = ["1", "2"].includes(page.at(-1)!) ? page.at(-1)! : null;
  return (
    <>
      <graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, functionButtonWidth, freqAreaHeight).fill(vscsColorNameMap.white);
        }}
      />
      <bitmapText
        text={pageTextMap[page]}
        x={page === "A/G_STATUS" ? 0 : fontWidth}
        style={{ fill: vscsColorNameMap.black, ...bitmapTextBaseStyle }}
      />
      {num && (
        <bitmapText
          text={num}
          x={fontWidth * 4 + 6}
          style={{ fill: vscsColorNameMap.black, fontFamily: pageNumbersFontName, fontSize: 16 }}
        />
      )}
    </>
  );
};

export const ScrnAltButton = ({ x = 0, y = 0 }) => {
  return (
    <container x={x} y={y}>
      <Page />
      <ModifierButton modifier="ALT_SCRN" text={"SCRN\nALT"} />
    </container>
  );
};
