import React, { useRef } from "react";
import { freqAreaHeight } from "components/FreqArea";
import { bitmapTextBaseStyle, buttonHeight, fontHeight, functionButtonWidth, gapSize, vscsColorNameMap } from "~/constants";
import type { Container } from "pixi.js";
import { usePressedListener } from "~/hooks/usePressedListener";

type PttFreqType = "UHF" | "VHF" | "BOTH";

const angleSize = 10;

type EmergencyPttButtonProps = {
  x?: number;
  y?: number;
  freqType: PttFreqType;
};
export const EmergencyPttButton = ({ x = 0, y = 0, freqType }: EmergencyPttButtonProps) => {
  const ref = useRef<Container>(null);
  const pressed = usePressedListener(ref, false);

  return (
    <container x={x} y={y + freqAreaHeight} eventMode="static" ref={ref}>
      <graphics
        draw={(graphics) => {
          graphics.clear();
          graphics.poly([
            angleSize,
            0,
            functionButtonWidth - angleSize,
            0,
            functionButtonWidth,
            angleSize,
            functionButtonWidth,
            buttonHeight - angleSize,
            functionButtonWidth - angleSize,
            buttonHeight,
            angleSize,
            buttonHeight,
            0,
            buttonHeight - angleSize,
            0,
            angleSize,
            angleSize,
            0,
          ]).fill(pressed ? vscsColorNameMap.blue : vscsColorNameMap.red);
        }}
      />
      {["PTT", freqType].map((line, i) => (
        <bitmapText
          key={i}
          x={angleSize}
          y={fontHeight * (i + 1)}
          text={line}
          style={{ ...bitmapTextBaseStyle, fill: vscsColorNameMap.white }}
        />
      ))}
    </container>
  );
};

export const EmergencyPttArea = ({ x = 0, y = 0 }) => {
  return (
    <container x={x} y={y}>
      <EmergencyPttButton
        x={0}
        freqType="UHF"
      />
      <EmergencyPttButton
        x={functionButtonWidth + gapSize}
        freqType="BOTH"
      />
      <EmergencyPttButton
        x={(functionButtonWidth + gapSize) * 2}
        freqType="VHF"
      />
    </container>);
}